<template>
  <div class="picWallBody" :style="{ width: width, height: height }">
    <a-carousel :dots="false" :autoplay="true" arrows>
      <div v-if="showArrow" slot="prevArrow" class="custom-slick-arrow">
        <a-icon type="left"></a-icon>
      </div>
      <div v-if="showArrow" slot="nextArrow" class="custom-slick-arrow">
        <a-icon type="right"></a-icon>
      </div>
      <!-- 对象数组 -->
      <div class="picBox">
        <img
          class="pic"
          v-for="(item, index) in picList[0].list"
          :key="index"
          :src="item.img"
          alt=""
        />
      </div>
      <div class="picBox">
        <img
          v-for="(e, i) in picList[1].list"
          :key="i"
          class="pic"
          :src="e.img"
          alt=""
        />
      </div>
    </a-carousel>
  </div>
</template>

<script>
export default {
  mounted() {
    let _this = this;
    _this.setViewPort();
    window.addEventListener("resize", () => {
      _this.setViewPort();
    });
  },
  data() {
    return {
      width: 1440 + "px",
      height: 460 + "px",
      showArrow: true,
      background:require("@/assets/images/picWallBackground.png"),
      picList: [
        {
          list: [
            {
              img: require("@/assets/images/pic_wall_1.png"),
            },
            {
              img: require("@/assets/images/pic_wall_2.png"),
            },
            {
              img: require("@/assets/images/pic_wall_3.png"),
            },
            {
              img: require("@/assets/images/pic_wall_4.png"),
            },
          ],
        },
        {
          list: [
            {
              img: require("@/assets/images/pic_wall_5.png"),
            },
            {
              img: require("@/assets/images/pic_wall_6.png"),
            },
            {
              img: require("@/assets/images/pic_wall_7.png"),
            },
            {
              img: require("@/assets/images/pic_wall_8.png"),
            },
          ],
        },
      ],
    };
  },
  methods: {
    setViewPort() {
      let tempWidth = (document.body.clientWidth / 1920) * 1440;
      this.width = tempWidth + "px";
      this.height = (tempWidth / 1440) * 460 + "px";
      if (tempWidth <= 750) {
        this.showArrow = false;
        tempWidth = (document.body.clientWidth / 750) * 750;
        this.width = tempWidth + "px";
        this.height = (tempWidth / 1440) * 600 + "px";
   
      }

      console.log("set view port", this.width, this.height);
    },
  },
};
</script>

<style scoped>
.picWallBody {
  display: flex !important;
  width: 100% ;
  /* background-color: #333333; */
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: url("@/assets/images/picWallBackground.png") no-repeat center;
  background-size: auto 100%;
}
.ant-carousel {
  height: 100%;
  width: 75%;
}
.ant-carousel :deep(.slick-slide) {
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slick-slider{
  width: 100%;
}
.ant-carousel :deep(.slick-slider) {
  height: 100%;
}

.ant-carousel :deep(.slick-list) {
  height: 100%;
}
.ant-carousel :deep(.slick-track) {
  height: 100%;
}
.picBox {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  height: 100% !important;
}
.pic {
  width: 24%;
  height: auto;
  display: inline-block !important;
  padding: 0 0.5rem;
}
.ant-carousel :deep(.slick-slide) {
  background: none !important;
}
.ant-carousel :deep(div) {
  height: 100%;
}
.ant-carousel :deep(.slick-dots-bottom) {
  bottom: -3.5rem !important;
}
#buttonRow {
  position: relative;
  width: 100%;
}
.anticon {
  color: #ffc81a;
  font-size: 28PX;
}

@media screen and (max-width:750px){
  .ant-carousel{
    width: 100%;
  }
  .picBox{
    width: 100% !important;
  }
  .slick-slide ::deep(div){
    width: 100%;
  }
  .pic {
  width: 25% !important;
  display: inline-block !important;
  padding: 0 0.5rem;
}
/**最大媒体宽度**/
}
</style>



