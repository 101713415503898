<template>
  <div id="bannerContainer">
    <img id="bannerPicture" :src="src" alt="" />
  </div>
</template>
<script>
export default {
  name: 'BannerPic',
  mounted() {},
  data() {
    return {};
  },
  props: {
    src: {
      type: String,
      default: ''
    },
    picHeight: {
      type: Number,
      default: 700
    },
    picWidth: {
      type: Number,
      default: 1920
    }
  },
  methods: {}
};
</script>
<style scoped>
#bannerContainer {
  width: 100%;
}
#bannerPicture {
  width: 100%;
  height: 100%;
}
</style>
