<template>
  <div
    id="titleContainer"
  >
    {{ this.title }}
    <div id="cutLine"></div>
    <div id="line"></div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      marginWidth: 0,
    };
  },
  methods: {
  },
};
</script>

<style scoped>
#titleContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  font-size: 24PX;
  padding: 0 20%;
  margin: 3rem 0 2rem 0;
}
#cutLine {
  position: relative;
  bottom: -0.3rem;
  width: 18.7rem;
  border-bottom: 0.5rem solid #ffc81a;
  box-sizing: border-box;
  z-index: 1;
}
#line {
  border-bottom: 0.1rem solid #999;
  width: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 750px){
  #titleContainer {
    padding: 0 12.5%;
    margin:3rem 0 1rem 0;
    font-size: 1.125rem;
  }
  #cutLine {
    width: 10rem;
  }
}
</style>