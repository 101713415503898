<template>
  <div id="app" ref="appBody">
    <div id="navigator">
      <HYNavigator />
    </div>

    <router-view></router-view>
    <PageFooter />
  </div>
</template>

<script>
import HYNavigator from '@/components/HYNavigator';
import PageFooter from '@/components/PageFooter';

export default {
  mounted() {
    let _this = this;
    _this.setRemUnit();
    window.addEventListener('resize', () => {
      _this.setRemUnit();
    });
  },
  data() {
    return {
      isMobile: false
    };
  },
  methods: {
    setRemUnit() {
      let widthItem = 1920
      const htmlEl = document.documentElement;
      const htmlWidth = htmlEl.clientWidth;
      if(htmlWidth >= 768){
        widthItem = 1920;
      }else{
        widthItem= 375
      }
      const htmlFontSize = (htmlWidth / widthItem) * 16;
      htmlEl.style.fontSize = htmlFontSize + 'px';
    }
  },
  components: {
    HYNavigator,
    PageFooter
  }
};
</script>

<style>
html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#navigator {
  width: 100%;
}
.ant-menu-overflowed-submenu {
  display: none !important;
}
</style>
