<template>
  <div id="homeViewBody">
    <BannerPic :src="homeViewBanner" ::pic-height="830" />
    <PageTitle title="Yarbo 2024系列新品上市" />
    <div id="homeViewContent">
      <BannerPic :src="snowDeviceBanner" :pic-width="1440" :pic-height="800" />
      <div class="space"></div>
      <BannerPic :src="grassDeviceBanner" :pic-width="1440" :pic-height="500" />
      <BannerPic
        :src="blowerDeviceBanner"
        :pic-width="1440"
        :pic-height="500"
      />
    </div>
    <PageTitle title="Yarbo家族系列" />
    <BannerPic :src="familyDeviceBanner" :pic-width="1920" :pic-height="1020" />
    <div id="moreAbout" @click="jumpTo(yarbo)">
      <span>访问Yarbo英文网站</span>
    </div>
    <PageTitle title="国外媒体报道" />
    <div id="foreignMedia">
      <a-row type="flex">
        <a-col
          class="picBox"
          :span="6"
          v-for="(item, index) in mediaList"
          :key="index"
        >
          <img class="mediaPic" :src="item.src" alt=""/>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import BannerPic from "@/components/BannerPic";
import PageTitle from "@/components/PageTitle";

export default {
  name: "HomeView",
  components: {
    BannerPic,
    PageTitle,
  },
  data() {
    return {
      homeViewBanner: require("@/assets/images/banner_home.png"),
      snowDeviceBanner: require("@/assets/images/banner_snow.png"),
      grassDeviceBanner: require("@/assets/images/banner_grass.png"),
      blowerDeviceBanner: require("@/assets/images/banner_blower.png"),
      familyDeviceBanner: require("@/assets/images/banner_family.png"),
      mediaList: [
        {
          src: require("@/assets/images/media_1.png"),
          url:'https://www.thestar.com/autos/2021/11/27/tired-of-shovelling-high-tech-snow-clearing-makes-it-easier-than-ever.html',
        },
        {
          src: require("@/assets/images/media_2.png"),
          url:'https://newatlas.com/robotics/snowbot-s1-snow-blowing-robot/',
        },
        {
          src: require("@/assets/images/media_3.png"),
          url:'https://www.coolthings.com/hanyang-robotics-snowbot-s1-autonomous-snowblower/',
        },
        {
          src: require("@/assets/images/media_4.png"),
          url:'https://www.coolthings.com/hanyang-robotics-snowbot-s1-autonomous-snowblower/',
        },
        {
          src: require("@/assets/images/media_5.png"),
          url:'https://www.fox10phoenix.com/news/snowbot-autonomous-robotic-snowblower-introduced-at-ces',
        },
        {
          src: require("@/assets/images/media_6.png"),
          url:'https://www.fox17online.com/national/newsy/consumer-electronics-show-highlights-new-generation-of-robots',
        },
        {
          src: require("@/assets/images/media_7.png"),
          url:'https://www.fox17online.com/national/newsy/consumer-electronics-show-highlights-new-generation-of-robots',
        },
        {
          src: require("@/assets/images/media_8.png"),
          url:'https://www.fox17online.com/national/newsy/consumer-electronics-show-highlights-new-generation-of-robots',
        },
        {
          src: require("@/assets/images/media_9.png"),
          url:'https://www.mansionglobal.com/articles/a-guide-to-smart-snow-removal-technology-223241',
        },
        {
          src: require("@/assets/images/media_10.png"),
          url:'https://robbreport.com/gear/electronics/snowbot-s1-hanyang-robotics-robot-snowblower-1234655701/',
        },
        {
          src: require("@/assets/images/media_11.png"),
          url:'https://www.techrepublic.com/pictures/ces-2022-15-weird-products-youll-find-in-las-vegas-this-week/',
        },
        {
          src: require("@/assets/images/media_12.png"),
          url:'https://uncrate.com/snowbot-s1-snow-blower-robot/',
        },
        {
          src: require("@/assets/images/media_13.png"),
          url:'https://uncrate.com/snowbot-s1-snow-blower-robot/',
        },
        {
          src: require("@/assets/images/media_14.png"),
          url:'https://www.yahoo.com/lifestyle/watch-adorable-robot-snowblower-clears-233000764.html?guce_referrer=aHR0cHM6Ly93d3cudGhlc25vd2JvdC5jb20v&guce_referrer_sig=AQAAACrsyFyAnC9sP740NFl4avlzG7jFiE8HJkVGqbFSMSlGo0DtaBKJ83Qssa4tfpIhZ7SGjspNgxVQfbUldTlj4JYu93JvVCPcX5rdaHB7NYQC3fEy8RFNJzVzAxDSkmCAPK76JeALfb4UZ8yNdll5Y7dWRsjNBNag1Db5bX-7pmUe&guccounter=2',
        },
        {
          src: require("@/assets/images/media_15.png"),
          url:'https://www.foxweather.com/lifestyle/shovels-are-so-last-year-this-robotic-snowblower-clears-your-driveway-for-you',
        },
        {
          src: require("@/assets/images/media_16.png"),
          url:'https://www.motor1.com/news/557039/robotic-snow-blower-snowbot/',
        },
      ],
      yarbo:"https://www.yarbo.com/"
    };
  },
  methods:{
    jumpTo(url){
      window.open(url);
    }
  }
};
</script>

<style>
.space {
  height: 1rem;
}
#homeViewBody {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.secondTitle {
  font-size: 1rem;
}

#homeViewContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 12.5%;
  width: 100%;
  box-sizing: border-box;
}

#moreAbout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 364.5px;
  height: 85px;
  font-size: 16PX;
  border: 3px solid #ffc81a; /*no*/
  box-sizing: border-box;
  margin: 59px 0;
  cursor: pointer;
}
#foreignMedia {
  padding: 0 248px 63px 248px;
}
.picBox {
  padding: 1rem 1rem !important;
  box-sizing: border-box;
}
.mediaPic {
  width: 100%;
  height: auto;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  #moreAbout {
    width: 20rem;
    height: 5rem;
    margin: 3.6578rem 0 0 0
  }
  #homeViewContent{
    padding: 0 5%;
  }
  #foreignMedia{
    padding: 1rem 5%;
  }
  .picBox{
    padding: 0.5rem 0.5rem !important;
  }
  
}
</style>
