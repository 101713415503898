<template>
  <div id="joinUsBody">
    <BannerPic :src="imgUrlList[0].url" />
    <PageTitle title="加入我们" />
    <div id="recruitmentContainer">
      <a-row style="display: flex; width: 100%; justify-content: center;">
        <a-col
          :span="recruitmentSpan"
          v-for="(item, index) in recruitmentChannel"
          :key="index"
          ><img
            class="recruitmentImage"
            :src="item.img"
            alt=""
            @click="jumpTo(item.url)"
        /></a-col>
      </a-row>
    </div>
    <div class="pageText">{{ recruitmentText_1 }}</div>
    <div class="pageText">{{ recruitmentText_2 }}</div>
    <PageTitle title="汉阳大家庭" />
    <PicWall />
    <PageTitle title="福利待遇" />
    <div class="pageText">{{ treatmentText }}</div>
    <div id="treatmentContainer">
      <a-row type="flex">
        <a-col :span="treatmentSpan">
          <img class="treatmentPic" :src="treatmentList[0].url" alt="" />
        </a-col>
        <a-col :span="treatmentSpan">
          <img class="treatmentPic" :src="treatmentList[1].url" alt="" />
        </a-col>
        <a-col :span="treatmentSpan">
          <img class="treatmentPic" :src="treatmentList[2].url" alt="" />
        </a-col>
        <a-col :span="treatmentSpan">
          <img class="treatmentPic" :src="treatmentList[3].url" alt="" />
        </a-col>
        <a-col :span="treatmentSpan">
          <img class="treatmentPic" :src="treatmentList[4].url" alt="" />
        </a-col>
        <a-col :span="treatmentSpan">
          <img class="treatmentPic" :src="treatmentList[5].url" alt="" />
        </a-col>
        <a-col :span="treatmentSpan">
          <img class="treatmentPic" :src="treatmentList[6].url" alt="" />
        </a-col>
        <a-col :span="treatmentSpan">
          <img class="treatmentPic" :src="treatmentList[7].url" alt="" />
        </a-col>
        <a-col :span="treatmentSpan">
          <img class="treatmentPic" :src="treatmentList[8].url" alt="" />
        </a-col>
        <a-col :span="treatmentSpan">
          <img class="treatmentPic" :src="treatmentList[9].url" alt="" />
        </a-col>
        <a-col :span="treatmentSpan">
          <img class="treatmentPic" :src="treatmentList[10].url" alt="" />
        </a-col>
        <a-col :span="treatmentSpan">
          <img class="treatmentPic" :src="treatmentList[11].url" alt="" />
        </a-col>
      </a-row>
    </div>
    <!-- <div id="welcome">
      <span> 汉阳科技欢迎您！</span>
    </div> -->
  </div>
</template>
  
  <script>
// @ is an alias to /src
import BannerPic from "@/components/BannerPic";
import PageTitle from "@/components/PageTitle";
import PicWall from "@/components/PicWall";
export default {
  name: "JoinUsView",
  mounted() {
    let _this = this;
    _this.resize();
    window.addEventListener("resize", () => {
      _this.resize();
    });
  },
  methods: {
    resize() {
      let width = document.body.clientWidth;
      if (width <= 750) {
        this.treatmentSpan = 12;
        this.recruitmentSpan = 12;
      }else{
        this.treatmentSpan = 8;
        this.recruitmentSpan = 8;
      }
    },
    jumpTo(url) {
      window.open(url);
    },
  },
  data() {
    return {
      imgUrlList: [{ url: require("@/assets/images/banner_joinUs.png") }],
      treatmentSpan: 8,
      treatmentList: [
        {
          url: require("@/assets/images/treatment_1.png"),
        },
        {
          url: require("@/assets/images/treatment_2.png"),
        },
        {
          url: require("@/assets/images/treatment_3.png"),
        },
        {
          url: require("@/assets/images/treatment_4.png"),
        },
        {
          url: require("@/assets/images/treatment_5.png"),
        },
        {
          url: require("@/assets/images/treatment_6.png"),
        },
        {
          url: require("@/assets/images/treatment_7.png"),
        },
        {
          url: require("@/assets/images/treatment_8.png"),
        },
        {
          url: require("@/assets/images/treatment_9.png"),
        },
        {
          url: require("@/assets/images/treatment_10.png"),
        },
        {
          url: require("@/assets/images/treatment_11.png"),
        },
        {
          url: require("@/assets/images/treatment_12.png"),
        },
      ],
      treatmentText:
        "我们始终坚信，人才是企业最重要的资产。你的喜怒哀乐，工作，生活，都与我们有关。你想到的没想到的，我们都给你准备好了！",
      recruitmentSpan: 6,
      recruitmentChannel: [
        {
          img: require("@/assets/images/recruitmentChannel_1.png"),
          url: "https://www.zhipin.com/gongsi/job/ecefd977ad9ebf411H1709i9.html",
        },
        // {
        //   img: require("@/assets/images/recruitmentChannel_2.png"),
        //   url: "https://jobs.51job.com/all/coA2MCYQRvBTMBbAVuVDk.html?timestamp__1258=n4%2BxcGDQite7uRxBdosGkU55GKtDveGODmrD&alichlgref=https%3A%2F%2Fwe.51job.com%2F",
        // },
        {
          img: require("@/assets/images/recruitmentChannel_3.png"),
          url: "https://www.liepin.com/company/8809483/",
        },
        // {
        //   img: require("@/assets/images/recruitmentChannel_4.png"),
        //   url: "https://maimai.cn/company?webcid=3gj4i57N&fr=search_sug&_share_channel=copy_link",
        // },
      ],
      recruitmentText_1:
        "人才是企业第一生产力，期待身怀技艺和智慧的您加入汉阳科技大家庭！",
      recruitmentText_2: "简历请投送至：zhangxiaomin@yarbo.com",
    };
  },
  components: {
    BannerPic,
    PageTitle,
    PicWall,
  },
};
</script>

  <style scoped>
#joinUsBody {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageText {
  padding: 1rem 3rem !important;
  font-size: 18PX;
}

#treatmentContainer {
  padding: 2rem 12.5%;
}
.treatmentPic {
  width: 100%;
}
.ant-col {
  padding: 0.5rem 0.9rem;
  box-sizing: border-box;
}
#recruitmentContainer {
  padding: 0 10%;
}
.recruitmentImage {
  width: 100%;
  cursor: pointer;
}
#welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 364px;
  height: 85px;
  font-size: 16PX;
  border: 3px solid #ffc81a; /*no*/
  box-sizing: border-box;
  margin: 56px 0;
}
#picWallContainer {
  width: 100%;
}

@media screen and (max-width: 750px) {
  #treatmentContainer {
    padding: 2rem 3%;
    overflow: hidden;
  }
  .ant-col {
    padding: 0.5rem 0.5rem;
    box-sizing: border-box;
  }
  #welcome {
    width: 20rem;
    height: 5rem;
    font-size: 16PX;
  }
}
</style>
  