import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
// import 'lib-flexible/flexible.js';
// import 'amfe-flexible';


Vue.use(Antd);
Vue.config.productionTip = false

// // 设置根元素的font-size，基准宽度为375px
// function setRemUnit() {
//   const baseSize = 37.5; // 对应设计稿的基准值，一般是设计稿宽度的1/10
//   const clientWidth = document.documentElement.clientWidth;
//   const fontSize = (clientWidth / 37.5) * baseSize; // 基于设计稿宽度调整
//   document.documentElement.style.fontSize = `${fontSize}px`;
// }

// // 初始化
// setRemUnit();

// // 当窗口大小变化时重新设置根元素的font-size
// window.addEventListener('resize', setRemUnit);

// // 手动切换基准宽度
// function switchBaseSize(newBaseSize) {
//   const clientWidth = document.documentElement.clientWidth;
//   const fontSize = (clientWidth / newBaseSize) * (newBaseSize / 10);
//   document.documentElement.style.fontSize = `${fontSize}px`;
//   // 触发 resize 事件以更新布局
//   window.dispatchEvent(new Event('resize'));
// }

// // 示例：切换到375px基准
// switchBaseSize(375);

// // 示例：切换回750px基准
// switchBaseSize(750);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})
