<template>
  <div class="aboutBody">
    <BannerPic :src="bannerPic" :pic-height="800" />
    <div id="aboutContent">
      <PageTitle title="公司介绍" />
      <div id="intr">
        <p>
          Yarbo汉阳科技」成立于2015年，总部位于深圳，在纽约、香港和新加坡设有办公地点，广东惠州和浙江嘉善设有生产工厂；是一家专注于消费级智能庭院维护机器人研发、生产和销售的创新科技公司，致力于提供高品质的电动化、智能化和无人化庭院维护体验。自成立之初，「Yarbo汉阳科技」就提出了适用于全季节、全场景庭院作业的“1+N”多功能产品设计理念，发明了全球首款消费级模块化庭院机器人，并率先推出首款消费级扫雪机器人。Yarbo多功能庭院机器人推出之初仅用1小时便突破了100万美元的众筹金额，众筹期间40多天时间共筹集超345万美元，成为全球消费类机器人领域中众筹金额最高的公司，并于2023年成功实现量产和全球交付。
        </p>
        <p>
          「Yarbo汉阳科技」坚持核心技术的自研自产，公司七成员工为研发人员。汉阳科技的产品理念侧重于易用、耐用、智能，旨在重新定义庭院劳作和生活方式，以提供轻松、舒适的高品质庭院维护体验。此外，「Yarbo汉阳科技」已受到包括ABC News、CBC、Forbes、Kaleva、CGTN、东方卫视和新华社在内的多家海内外主流媒体的报道，以及The Verge、Wired、CNET和ZDNET等海外知名科技媒体的持续关注。
        </p>
        <p>
          海外官网：<span style="color: #006fd0;" @click="jumpTo('https://www.yarbo.com/')">
            https://www.yarbo.com/
          </span> 
        </p>
        <p>
          微信公众号：Yarbo
        </p>
      </div>
      <PageTitle title="发展历史" />
      <div id="history">
        <bannerPic :src="isMoble ? historyPicMb : historyPic" :pic-width="1440" />
      </div>
      <div id="targetContainer">
        <div id="targetRow" type="flex">
          <div
            class="targetCol"
            v-for="(item, index) in targetPicList"
            :key="index"
          >
            <img class="targetPic" :src="item" alt="" />
          </div>
        </div>
      </div>
      <PageTitle title="荣誉资质" />
      <div id="certificateContainer">
        <div id="certificateRow" type="flex">
          <div
            class="certificateCol"
            v-for="(item, index) in certificateList"
            :key="index"
          >
            <img class="certificatePic" :src="item" alt="" />
          </div>
        </div>
        <div id="certificateRow" type="flex">
          <div
            class="certificateCol"
            v-for="(item, index) in certificateIn2023"
            :key="index"
          >
            <img class="certificatePic2023" :src="item" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import BannerPic from "@/components/BannerPic";
import PageTitle from "@/components/PageTitle";
export default {
  name: "AboutView",
  components: {
    BannerPic,
    PageTitle,
  },
  data() {
    return {
      bannerPic: require("@/assets/images/banner_about.png"),
      introPicList: [
        require("@/assets/images/introPic_1.png"),
        require("@/assets/images/introPic_2.png"),
      ],
      introText: [
        "「Yarbo汉阳科技」成立于2015年，总部设在深圳，此外在纽约、香港和新加坡设有办公地点，广东惠州和浙江嘉善设有生产工厂。作为一家专注于消费级智能庭院维护机器人的创新科技公司，「Yarbo汉阳科技」致力于提供高品质的电动化、智能化和无人化庭院维护体验。自成立之初，「Yarbo汉阳科技」就提出了适用于全季节、全场景庭院作业的“1+N”多功能产品设计理念，并坚持核心技术的自研自产。Yarbo发明了全球首款消费级模块化设计，并率先推出首款消费级扫雪机器人模块，推出之初仅用1小时便突破了100万美元的众筹金额，整个众筹期间共筹集345万美元，成为全球消费类机器人领域中众筹金额最高的公司，并也已成功实现量产和海外交付。",
        "「Yarbo汉阳科技」专注于设计、研发、生产、销售以及服务于庭院智能机器人产品，公司八成员工为研发人员。历经八年八次迭代，Yarbo已延展到20多种庭院需求。其主要业务包括向拥有庭院的用户提供集清理、维护及娱乐多功能于一体的自主服务机器人，业务遍及北美和欧洲等地区。汉阳科技的产品理念侧重于易用、耐用、智能，旨在重新定义庭院劳作和生活方式，以提供轻松、舒适的高品质庭院维护体验。此外，「Yarbo汉阳科技」已受到包括ABC News、CBC、Forbes、Kaleva、CGTN、东方卫视和新华社在内的多家海内外主流媒体的报道，以及The Verge、Wired、CNET和ZDNET等海外知名科技媒体的持续关注。",
        "海外官网：https://www.yarbo.com/",
        "微信公众号：Yarbo",
      ],
      historyPic: require("@/assets/newImages/banner_history.png"),
      historyPicMb: require("@/assets/newImages/banner_history_mb.png"),
      isMoble: false,
      targetPicList: [
        require("@/assets/images/target_1.png"),
        require("@/assets/images/target_2.png"),
        require("@/assets/images/target_3.png"),
      ],
      certificateList: [
        require("@/assets/images/certificate_1.png"),
        require("@/assets/images/certificate_2.png"),
      ],
      certificateIn2023: [
        require("@/assets/newImages/1.webp"),
        require("@/assets/newImages/2.webp"),
        require("@/assets/newImages/3.webp"),
        require("@/assets/newImages/4.webp"),
        require("@/assets/newImages/5.webp"),
      ],
    };
  },
  methods:{
    jumpTo(url){
      window.open(url);
    },
    setRemUnit() {
      if (window.innerWidth <= 768) {
        this.isMoble = true;
      } else {
        this.isMoble = false
      }
    }
  },
  mounted() {
    let _this = this;
    _this.setRemUnit();
    window.addEventListener('resize', () => {
      _this.setRemUnit();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setRemUnit);
  }
};
</script>

<style scoped>
#aboutContent {
  padding: 0 12.5%;
}
#intr {
  box-sizing: border-box;
}
p {
  text-align: start;
  font-size: 16PX;
}

#intrRow {
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 0 0 3rem 0;
}
.introPic {
  width: 90%;
  height: auto;
}
#history {
  display: flex;
  align-items: center;
  justify-content: center;
}
#targetContainer {
  box-sizing: border-box;
  padding: 3rem 0 0 0;
}
.targetPic {
  width: 100%;
}
#targetRow {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.targetCol {
  width: 30%;
}
#certificateContainer {
  width: 100%;
}

#certificateRow {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 3rem 0;
}
.certificateCol {
  display: flex;
  align-items: end;
  justify-content: space-between;
}
.certificatePic {
  width: 100%;
  height: auto;
}
.certificatePic2023{
  width: auto;
  height: 10rem;
  display: flex;
  flex-grow: 1;
}

@media screen and (max-width: 750px) {
  #aboutContent {
    padding: 0 4%;
  }
  #targetRow {
    display: flex;
    flex-direction: column;
  }
  .targetCol {
    width: 80%;
  }

  #certificateRow {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .certificateCol {
    padding: 0.3125rem 0.625rem;
  }
  .introPic {
    width: 95%;
    height: auto;
  }
  #history div {
    width: 100% !important;
    height: auto !important;
  }

  .certificatePic2023{
    width: auto;
    height: 5rem;
    display: flex;
    flex-grow: 1;
  }
}
</style>
