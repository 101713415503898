<template>
  <div id="contactBody">
    <BannerPic class="pic" :src="bannerSrc" :pic-height="800" :pic-width="1920" />
    <div id="contactContent">
      <PageTitle title="公司地址" />
      <div id="addressContainer">
        <img id="map" :src="mapSrc" alt="" />
      </div>
      <div class="contactInfoRow">
        <div class="contactInfo addressPBox">
          <p class="address" v-for="(item, index) in addressList" :key="index">
            {{ item }}
          </p>
        </div>
        <div class="contactInfo emailPBox">
          <p
            class="address"
            v-for="(item, index) in surveyContact"
            :key="index"
          >
            {{ item }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
// @ is an alias to /src
import BannerPic from "@/components/BannerPic";
import PageTitle from "@/components/PageTitle";
export default {
  name: "ContactView",
  components: {
    BannerPic,
    PageTitle,
  },
  data() {
    return {
      bannerSrc: require("@/assets/images/banner_contact.png"),
      mapSrc: require("@/assets/images/contact_map.png"),
      addressList: [
        "深圳：深圳市龙华区龙华街道清湖社区宝能科技园5栋518",
        "美国：1951 Ocean Avenue Unit 3, Ronkonkoma, NY 11779 ",
      ],
      surveyContact: [
        "汉阳已离职人员背调联系方式",
        "联系人：Carina",
        "邮箱：zhangxiaomin@yarbo.com",
      ],
      yarbo: "https://www.yarbo.com/",
    };
  },
  methods: {
    jumpTo(url) {
      window.open(url);
    },
  },
};
</script>

<style scoped>
#contactBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#contactContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 0 12.5%;

}
#addressContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#map {
  width: 100%;
}
.address {
  font-size: 20PX;
}
.contactInfoRow {
  width: 100%;
  padding: 0 0 140px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}
.contactInfo {
  display: flex;
  flex-direction: column;
  align-items: start;
}

@media screen and (max-width: 750px) {
  #contactBody {
    padding: 0 4%;
  }

  .address {
    font-size: 12PX;
  }
  .contactInfoRow{
    display: block;
    padding: 20px 0;
  }
  .contactInfo{
    margin: 30px 0;
  }
}
</style>
  