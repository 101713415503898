import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import JoinUsView from '../views/JoinUsView.vue'
import ContactView from '../views/ContactView.vue'

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: "/home",
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      title: '深圳汉阳科技有限公司官网'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: {
      title: '企业介绍｜深圳汉阳科技'
    }
  },
  {
    path: '/join',
    name: 'join',
    component: JoinUsView,
    meta: {
      title: '加入我们｜深圳汉阳科技'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: {
      title: '联系我们｜深圳汉阳科技'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
