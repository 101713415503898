<template>
  <div id="footerBody">
    <div v-if="showPC" id="footerTop">
      <div id="footerTop_left">
        <img id="footerLogo" :src="footerLogo" alt="" />
      </div>
      <div id="footerTop_right">
        <a-row type="flex">
          <a-col :span="6" @click="routeTo('join')"
            ><span>加入我们</span></a-col
          >
          <a-col :span="18" @click="routeTo('contact')"
            ><span>联系我们</span></a-col
          >
        </a-row>
        <a-row type="flex">
          <a-col :span="6" @click="routeTo('about')"
            ><span>企业介绍</span></a-col
          >
          <a-col :span="18"
            ><span
              >地址：深圳市龙华区龙华街道清湖社区宝能科技园5栋518</span
            ></a-col
          >
        </a-row>
        <a-row type="flex">
          <a-col :span="6"
            ><span @click="jumpTo(privacyUrl)">隐私声明</span></a-col
          >
          <a-col :span="18"><span>电话：0755-86728090</span></a-col>
        </a-row>
        <a-row type="flex">
          <a-col :span="6"
            ><span @click="jumpTo(agreementUrl)">服务协议</span>
          </a-col>
          <a-col :span="18"><span>邮箱：support@yarbo.com</span></a-col>
        </a-row>
      </div>
    </div>

    <div id="footer_m" v-else>
      <div class="footerButton" @click="jumpTo(privacyUrl)">隐私声明</div>
      <div class="footerButton" @click="jumpTo(agreementUrl)">服务协议</div>
      <div class="footerButton">电话：0755-86728090</div>
      <div class="footerButton">邮箱：support@yarbo.com</div>
      <div class="footerButton">
        地址：深圳市龙华区龙华街道清湖社区宝能科技园5栋518
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
  mounted() {
    let _this = this;
    _this.setHeight();
    window.addEventListener('resize', () => {
      _this.setHeight();
    });
  },
  data() {
    return {
      footerLogo: require('@/assets/images/footer_logo.png'),
      showPC: true,
      privacyUrl: 'https://www.yarbo.com/us/legal/privacy-policy',
      agreementUrl: 'https://www.yarbo.com/us/legal/terms-of-service'
    };
  },
  methods: {
    setHeight() {
      if (document.body.clientWidth < 768) {
        this.showPC = false;
      } else {
        this.showPC = true;
      }
    },
    jumpTo(url) {
      window.open(url);
    },
    routeTo(route) {
      this.$router.push({ path: route });
    }
  }
};
</script>

<style scoped>
#footerBody {
  display: flex;
  flex-direction: column;
  background-color: #333333;
}
#footerTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 85%;
}
#footerTop_left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8rem;
}
#footerLogo {
  max-width: 24rem;
}
#footerTop_right {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 4.125rem 0;
}
#footerBottom {
  height: 15%;
  background-color: #ffc500;
}
.ant-col {
  color: #fff;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 1.25rem;
  padding: 0.5rem 0;
}
#footer_m {
  padding: 1rem;
}

.footerButton {
  text-align: start;
  vertical-align: middle;
  padding: 0.5rem 0; /* no */
  border-bottom: 1px solid #fff;
  color: #fff;
  font-size: 1rem;
}
</style>
