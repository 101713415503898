<template>
  <div v-if="showPC" id="navigatorContainer">
    <div id="logoContainer">
      <CompanyLogo />
    </div>
    <a-menu v-if="showPC" mode="horizontal">
      <a-menu-item @click="this, routeTo('/home')">首页</a-menu-item>
      <a-menu-item @click="this, routeTo('/about')">企业介绍</a-menu-item>
      <a-menu-item @click="this, routeTo('/contact')">联系我们</a-menu-item>
      <a-menu-item @click="this, routeTo('/join')">加入我们</a-menu-item>
    </a-menu>
  </div>
  <div id="navigatorContainer_m" v-else>
    <div id="logoContainer_m">
      <CompanyLogo />
    </div>
    <div id="menuToggle">
      <a-icon
        class="toggleIcon"
        @click="showMenu"
        v-if="!menuShow"
        type="unordered-list"
      >
      </a-icon>
      <a-icon class="toggleIcon" @click="showMenu" v-else type="close">
      </a-icon>
    </div>
    <div id="menu_m" v-if="!showPC">
      <div @click="this, routeTo('/home')" class="menu_block">首页</div>
      <div class="cutline"></div>
      <div @click="this, routeTo('/about')" class="menu_block">企业介绍</div>
      <div class="cutline"></div>
      <div @click="this, routeTo('/contact')" class="menu_block">联系我们</div>
      <div class="cutline"></div>
      <div @click="this, routeTo('/join')" class="menu_block">加入我们</div>
    </div>
  </div>
</template>
<script>
import CompanyLogo from "@/components/CompanyLogo";

export default {
  name: "HYNavigator",
  components: {
    CompanyLogo,
  },
  mounted() {
    let _this = this;
    _this.setMode();
    window.addEventListener("resize", () => {
      _this.setMode();
    });
  },
  data() {
    return {
      showPC: true,
      menuShow: false,
    };
  },
  methods: {
    setMode() {
      if (document.body.clientWidth <= 750) {
        this.showPC = false;
      }else{
        this.showPC = true
      }
    },
    routeTo(name) {
      if (this.menuShow) {
        this.showMenu()
      }
      this.$router.push(name);
    },
    showMenu() {
      this.menuShow = !this.menuShow;
      console.log($("menuM"));
      if (this.menuShow) {
        $("#menu_m").css({ top: "3.75rem" });
      } else {
        $("#menu_m").css({ top: "-20rem" });
      }
    },
  },
};
</script>

<style scoped>
#logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 297PX;
  height: 120px;
}

router-link {
  text-decoration: none;
}
#navigatorContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100px;
  background-color: #eeeeee;
  position: relative;
  z-index: 3;
}
.ant-menu {
  background-color: #eeeeee !important;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex: 1;
  min-width: 370px;
}
.ant-menu-item {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  color: #333 !important;
  height: 100%;
  box-sizing: border-box !important;
  border-bottom: 3PX solid transparent !important;
  font-size: 20PX;
}
.ant-menu-item-selected {
  border-bottom: 3PX solid transparent !important;
}
.ant-menu-item:hover {
  border-bottom: 3PX solid #ffc81a !important;
}
.toggleIcon {
  color: #333;
  font-size: 20PX;
}

/* 移动端navigator */
#navigatorContainer_m {
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #eeeeee;
}
/* 低层白色 */
#logoContainer_m {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3.75rem;
  background-color: #eeeeee;
}
/* 菜单开关 */
#menuToggle {
  position: absolute;
  width: 30px;
  right: 30px;
  z-index: 4;
}
/* 菜单 */
#menu_m {
  background-color: #eeeeee;
  position: absolute;
  z-index: 2;
  width: 100%;
  top: -90rem;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0.625rem 1.25rem;
  border-radius: 0 0 8px 8px;
}
.menu_block {
  display: flex;
  width: 100%;
  height: 1.875rem;
  color: #333;
  font-size: 15PX;
  align-items: center;
}
.cutline {
  width: 100%;
  height: 1px; /* no */
  background-color: #333;
}
</style>