<template>
    <img id="logo" src="@/assets/newImages/yarbo-logo.png" alt="" @click ="this.routeTo">
</template>
<script>
export default {
  name: 'CompanyLogo',
  props: {
    msg: String
  },
  methods: {
    routeTo(){
      this.$router.push('/')
    }
  },
}
</script>
<style scoped>
#logo{
  width: 50%;
  height: auto;
  cursor: pointer;
  box-sizing: border-box;
}
</style>
